import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'
import { motion, useViewportScroll, transform } from 'framer-motion'
import Fade from 'react-reveal/Fade'
import { usePortals } from "react-portal-hook";

import { Layout, PageHero, PageSection, Lightbox, VideoCover } from '../components'

import { container, padding, colours, type, bgImage, bgIcon, pagePaddingTop, wrapper } from '../styles/global'
import { parseACF } from '../utils'
import { useScrollAnim, transition } from '../utils/anim'
import { media } from '../styles/utils'

const About = (props) => {
	const data = parseACF(useStaticQuery(query), 'allWordpressInfopages', true)
	const portalManager = usePortals()

	const changeSlide = (slideIndex, slides, isVideo) => {
		portalManager.open(modal => (
			<Lightbox
				slides={slides}
				activeSlide={slideIndex}
				isVideo={isVideo}
				{...modal}
			/>
        ))
	}
	
	const renderBio = () => {	
		const section = {
			title: 'Bio',
			className: 'bio',
			items: [
				{
					intro_text: data.bio_text_intro,
					body_text: data.bio_text_body,
					image: data.bio_image,
					image_or_animation: 'image'
				}
			]
		}	

		return (
			<PageSection 
				{...section}
			/>
		)
	}

	const renderGalleryGrid = () => {
		if (!data.gallery_images) return;
		
		const items = data.gallery_images.map((item, i) => {
			const widths = [60, 40, 40, 60]
			const [ref, { opacity, y }] = useScrollAnim();

			return (
				<GalleryItem 
					key={i}
					width={widths[i % widths.length]}
					onClick={() => changeSlide(i, data.gallery_images, false)}
					ref={ref}
					animate={{
						y,
						opacity,
						transition: {
							...transition,
							duration: 0.7,
							delay: i * 0.1
						}
					}}
				>
					<Image
						key={item.image?.sizes?.medium2}
						src={item.image?.sizes?.medium2}
					>
						{(src, loading) => {
							return (
								<BGImage 
									image={src} 
									style={{opacity: loading ? 0 : 1}}  
								/>
							)
						}}
					</Image> 	
					<Icon/>
				</GalleryItem>
			)
		})

		return (
			<Gallery>
				{items}
			</Gallery>
		)
	}
	
	const renderGallery = () => {	
		if (!data.gallery_title) return;

		const section = {
			title: 'Gallery',
			renderContent: renderGalleryGrid
		}	

		return (
			<PageSection 
				{...section}
			/>
		)
	}

	const renderMediaGrid = () => {
		if (!data.media_videos) return;
		
		const items = data.media_videos.map((item, i) => {
			const [ref, { opacity, y }] = useScrollAnim();

			return (
				<MediaItem 
					key={i}
					onClick={() => changeSlide(i, data.media_videos, true)}
					ref={ref}
					animate={{
						y,
						opacity,
						transition: {
							...transition,
							duration: 0.7,
							delay: i * 0.1
						}
					}}
				>
					<VideoCover
						data={item}
					/>
				</MediaItem>
			)
		})

		return (
			<Gallery>
				{items}
			</Gallery>
		)
	}

	const renderMedia = () => {	
		if (!data.media_title) return;

		const section = {
			title: 'In the media',
			renderContent: renderMediaGrid
		}	

		return (
			<PageSection 
				{...section}
			/>
		)
	}

	return (
		<Layout
            meta={data.seo}
        >
			<Wrapper>
                <PageHero
                    data={data}
                />

                <Container>
					{renderBio()}
					{renderGallery()}
					{renderMedia()}
                </Container>
			</Wrapper>
		</Layout>	
	)
}


// Shared

const Heading = styled(motion.h2)``
const Subheading = styled.h3``
const Text = styled.div``
const Info = styled.div``

const Icon = styled(motion.div)`
	background-image: url(${props => props.image});  
	${bgIcon};
`

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled(motion.div)`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

const Video = styled(ReactPlayer)``

// Layout

const Wrapper = styled.div`
	${wrapper}
`

const Container = styled.div`
	${container}
	${padding}
	${pagePaddingTop}

	display: flex;
	flex-direction: column;

	.content {
		${media.tablet`
			margin-right: 32px;
		`}

		${media.phone`
			margin-right: 0;
		`}
	}

	/* Mobile intro image override */

	${media.phone`
		.page-section.bio {
			.media {
				order: 1
			}

			.intro {
				order: 0
			}

			.body {
				order: 2
			}
		}
	`}
`

// Gallery

const Gallery = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;

	${media.phone`
		flex-direction: column;
	`}
`

const GalleryItem = styled(motion.div)`
	flex: 0 1 calc(${props => props.width}% - 15px);
	flex-basis: calc(${props => props.width}% - 15px);
	margin-bottom: 30px;
	position: relative;
	cursor: pointer;
	overflow: hidden;

	${BGImage} {
		height: 30.5vw;
		transition: transform 1s ease;
		will-change: transform;
	}

	${Icon} {
		position: absolute;
		top: 50%;
		left: 50%;
		opacity: 0;
		transform: translate(-50%, -50%);
		transition: opacity 0.5s ease;
		background-image: url(${require('../assets/icons/gallery-show.svg')});
		z-index: 2;
		width: 24px;
		height: 24px;
	}

	&:hover {
		${Icon} {
			opacity: 1
		}

		${BGImage} {
			transform: scale(1.05);
		}
	}

	${media.phone`
		flex: 1 0 auto;
		margin-bottom: 16px;

		${BGImage} {
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	`}

	${media.phone`
		${props => {
			if (props.width == 60) return css`
				padding-bottom: 60%;
			`

			if (props.width == 40) return css`
				padding-bottom: 110%;
			`
		}}
	`}
`

// Media Grid

const MediaItem = styled(motion.div)`
	flex: 0 1 calc(50% - 15px);
	flex-basis: calc(50% - 15px);
	margin-bottom: 30px;
	height: 34vh;

	.react-player__preview {
		pointer-events: none;
	}

	${media.phone`
		flex: 1 0 auto;
		height: 50vw;	
		margin-bottom: 16px;
	`}
`


export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "about" } }) {
            nodes {
                title
                slug
                acf_json
            }
        }
    }
`

export default About